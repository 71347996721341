import { Theme, alpha } from '@mui/material/styles';
//
import { ColorSchema } from '../palette';

// ----------------------------------------------------------------------

export default function ToggleButton(theme: Theme) {
  const isLight = theme.palette.mode === 'light';

  const style = (color: ColorSchema) => ({
    props: { color },
    style: {
      '&:hover': {
        borderColor: alpha(theme.palette[color].main, 0.48),
        backgroundColor: alpha(theme.palette[color].main, theme.palette.action.hoverOpacity),
      },
      '&.Mui-selected': {
        borderColor: alpha(theme.palette[color].main, 0.48),
      },
    },
  });

  return {
    MuiToggleButton: {
      variants: [
        {
          props: { color: 'standard' },
          style: {
            '&.Mui-selected': {
              backgroundColor: theme.palette.action.selected,
            },
          },
        },
        style('primary'),
        style('secondary'),
        style('info'),
        style('success'),
        style('warning'),
        style('error'),
        {
          props: { color: "tag" },
          style: {
            color: theme.palette.text.secondary,
            backgroundColor: isLight ? theme.palette.bg.warmGrey0 : theme.palette.background.neutral,

            '&:hover': {
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
            },

            '&.Mui-selected': {
              "& svg": {
                color: theme.palette.primary.main,
              },
              color: theme.palette.text.white,
              borderColor: isLight ? "transparent" : theme.palette.primary.main,
              backgroundColor: isLight ? theme.palette.bg.warmGrey3 : alpha(theme.palette.primary.darker, .75),
            },

            '&&.Mui-selected:hover': {
              backgroundColor: alpha(theme.palette.bg.warmGrey3, 0.75),
            },
          },
        }
      ],
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          backgroundColor: theme.palette.background.paper,
          border: `solid 1px ${theme.palette.grey[500_12]}`,
          '& .MuiToggleButton-root': {
            margin: 4,
            borderColor: 'transparent !important',
            borderRadius: `${theme.shape.borderRadius}px !important`,
          },
        },
      },
    },
  };
}

declare module "@mui/material/ToggleButton" {
  interface ToggleButtonPropsColorOverrides {
    tag: true
  }
}