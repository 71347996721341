import { Box, Button, Dialog, Divider, IconButton, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { requestNewAsset } from '../../clients/AssetClient'
import Iconify from '../Iconify'
import { FormProvider } from '../hook-form'
import { LoadingButton } from '@mui/lab'
import RequestNewAssetForm from './RequestNewAssetForm'

const FormSchema = Yup.object().shape({
  fileType: Yup.string().nullable(),
  message: Yup.string().nullable().required('Message is required'),
})
const resolver = yupResolver(FormSchema)
type FormValues = { fileType: string, message: string }
const DEFAULT_VALUES = { fileType: '', message: '' }

export function RequestNewAssetButton(){
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const methods = useForm<FormValues>({resolver, defaultValues: DEFAULT_VALUES });
  const {handleSubmit, reset} = methods
  const {enqueueSnackbar} = useSnackbar();

  // reset the form whenever the form opens
  useEffect(() => {
    if(open){
      setLoading(false)
      reset(DEFAULT_VALUES)
    }
  }, [open])

  // handle the form submission
  const onSubmit = async (data: FormValues) => {
    setLoading(true)
    await requestNewAsset(data)
    enqueueSnackbar((<>
      <Stack spacing={1} direction="row" alignItems="center">
        <Typography variant="smallHighlight">👍 Success!</Typography>
        <Typography component="div" variant="small" noWrap>Your request has been sent</Typography>
      </Stack>
    </>))
    setOpen(false)
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={() => setOpen(true)}
        startIcon={<Iconify icon="eva:bulb-outline"/>}
      >
        Request An Asset
      </Button>

      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => setOpen(false)}
      >

        <Box p={7}>
          <Box sx={{position: "absolute", right: 0, top: 0, mr: 1, mt: 1}}>
            <IconButton onClick={() => setOpen(false)}>
              <Iconify icon="eva:close-fill"/>
            </IconButton>
          </Box>

          <Typography variant="h2" mb={5}>Request New Asset</Typography>

          <Box sx={{mb: 5}}/>

          <FormProvider methods={methods}>
            <RequestNewAssetForm />
          </FormProvider>

          <Divider sx={{my: 3}}/>

          <Stack spacing={3} >
            <LoadingButton
              loadingPosition="start"
              loading={loading}
              fullWidth={true}
              startIcon={<Iconify icon="eva:arrow-ios-forward-fill"/>}
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Submit New Asset Request
            </LoadingButton>
            <Button
              fullWidth={true}
              variant="text"
              sx={{bgcolor: "white"}}
              onClick={() => {
                setOpen(false)
              }}
            >
              Cancel
            </Button>
          </Stack>

        </Box>


      </Dialog>
    </>
  )
}