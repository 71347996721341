import { ReactNode, useMemo } from 'react'
import { alpha, createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette'
import componentsOverride from './overrides'
import { useBootstrap } from '../hooks/useBootstrap.ts'
import { darken, lighten } from '@mui/material'
import NotistackProvider from '../components/NotistackProvider.tsx'

export function CompanyThemeProvider({ children }: { children: ReactNode }) {
  const defaultTheme = useTheme()
  const { data: bootstrap } = useBootstrap()
  const palette = bootstrap?.branding?.palette || DEFAULT_PALETTE
  const theme = useMemo(
    () => {
      const primaryColor = palette.primary
      const secondaryColor = palette.secondary
      const primaryOverride = primaryColor == 'default' || !isValidColor(primaryColor) ?
        defaultTheme.palette.primary :
        {
          lighter: lighten(primaryColor, .5),
          light: lighten(primaryColor, .3),
          main: primaryColor,
          dark: darken(primaryColor, .3),
          darker: darken(primaryColor, .6),
        } as SimplePaletteColorOptions
      const secondaryOverride = secondaryColor == 'default' || !isValidColor(secondaryColor) ?
        defaultTheme.palette.secondary :
        {
          lighter: lighten(secondaryColor, .5),
          light: lighten(secondaryColor, .3),
          main: secondaryColor,
          dark: darken(secondaryColor, .3),
          darker: darken(secondaryColor, .6),
        } as SimplePaletteColorOptions
      const options = {
        ...defaultTheme,
        palette: {
          ...defaultTheme.palette,
          primary: primaryOverride,
          secondary: secondaryOverride,
          background: {
            ...defaultTheme.palette.background,
            default: defaultTheme.palette.grey['900'],
            paper: defaultTheme.palette.grey['800'],
          },
        },
        customShadows: {
          ...defaultTheme.customShadows,
          primary: `0 8px 16px 0 ${alpha(primaryOverride.main, 0.24)}`,
        },
      }

      const theme = createTheme(options)
      theme.components = componentsOverride(theme)

      // extra component overrides
      theme.components = {
        ...theme.components,

        // override the
        MuiAppBar: {
          styleOverrides: {
            root: {
              background: theme.palette.background.default,
              borderBottom: `1px solid ${theme.palette.divider}`,
            },
          },
        },
      }

      return theme
    }, [palette, defaultTheme])

// NotistackProvider is impacted by the theme, so we override it after
  return (
    <ThemeProvider theme={theme}>
      <NotistackProvider>
        {children}
      </NotistackProvider>
    </ThemeProvider>
  )
}

const DEFAULT_PALETTE = { primary: 'default', secondary: 'default' }

function isValidColor(color: string) {
  if (!color) return false
  if (color.charAt(0) != '#') return false
  if (color.length != 4 && color.length != 7) return false
  return true
}