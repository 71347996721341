import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getCompanyBrandSettings,
  getCompanyInfo,
  getCompanySharingSettings,
  updateCompanyBrand,
  updateCompanyInfo,
  updateCompanyLogo,
  updateCompanySharingSettings,
} from '../clients/CompanyClient'
import { UpdateCompanyBrandRequest, UpdateCompanyInfoRequest } from '../@types/company'
import { GeneralAccessType, ShareLevel } from '../@types/sharing'

export function useCompanyInfo(){
  return useQuery({
    queryKey: ["company-info"],
    queryFn: async() => getCompanyInfo()
  })
}

export function useCompanyBranding(){
  return useQuery({
    queryKey: ["company-brand"],
    queryFn: async() => getCompanyBrandSettings()
  })
}

export function useCompanySharingSettings(){
  return useQuery({
    queryKey: ['company-sharing-settings'],
    queryFn: async() => getCompanySharingSettings()
  })
}

type UpdateCompanyInfoProps = UpdateCompanyInfoRequest

export function useUpdateCompanyInfo(){
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (data: UpdateCompanyInfoProps) => updateCompanyInfo(data),
    onSuccess: async data => {
      queryClient.invalidateQueries({ queryKey: ["company-info"] })
    },
  })
}

type UpdateCompanyBrandProps = UpdateCompanyBrandRequest
export function useUpdateCompanyBranding(){
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (data: UpdateCompanyBrandProps) => updateCompanyBrand(data),
    onSuccess: async data => {
      queryClient.invalidateQueries({ queryKey: ["company-brand"] })
      queryClient.invalidateQueries({ queryKey: ['user-bootstrap']})
    },
  })
}

export function useUpdateCompanyLogo(){
  return useMutation({
    mutationFn: async (file: File) => updateCompanyLogo(file),
  })
}

type UpdateCompanySharingSettingsProps = {
  accessType: GeneralAccessType,
  shareLevel: ShareLevel
}
export function useUpdateCompanySharingSettings(){
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: UpdateCompanySharingSettingsProps) =>
      updateCompanySharingSettings(data.accessType, data.shareLevel),
    onSuccess: async data => {
      queryClient.invalidateQueries({ queryKey: ["company-sharing-settings"] })
    }
  })
}
