import { useDownload } from '../../../hooks/useDownload.tsx'
import { AssetStorageType } from '../../../@types/asset.ts'
import { Box, IconButton, Tooltip } from '@mui/material'
import Iconify from '../../Iconify.tsx'
import { SearchAssetResult } from '../../../@types/search.ts'
import { IconButtonOwnProps } from '@mui/material/IconButton/IconButton'
import { UserType } from '../../../@types/user.ts'
import useAuth from '../../../hooks/useAuth.ts'

export function AssetConsumeIconButton({ asset, size = 'small', color = undefined }: {
  asset: SearchAssetResult,
  size?: IconButtonOwnProps['size'],
  color?: IconButtonOwnProps['color']
}) {
  const { consumeAsset } = useDownload()
  const { user } = useAuth()
  const handleConsumeClick = () => consumeAsset({ assetId: asset.assetId, storageType: asset.storageType })
  const isAdmin = user?.userType == UserType.ADMIN
  const downloadDisabled = asset?.isInternal && !isAdmin
  const tooltip =
    downloadDisabled ? 'This asset is for internal use only. To prevent accidental distribution, downloading has been disabled.' :
      asset.storageType == AssetStorageType.INTERNAL_S3 ? 'Download' :
        'Open Link'
  const icon =
    downloadDisabled ? 'ph:lock' :
      asset.storageType == AssetStorageType.INTERNAL_S3 ? 'ph:download' :
        'ph:link-simple-horizontal'
  return (
    <Tooltip title={tooltip}>
      <Box>
        <IconButton
          onClick={handleConsumeClick}
          size={size}
          color={color}
          disabled={downloadDisabled}
        >
          <Iconify icon={icon} />
        </IconButton>
      </Box>
    </Tooltip>
  )
}