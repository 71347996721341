import { Box, Button, Stack, Tab, Tabs } from '@mui/material'
import { useMemo } from 'react'
import Iconify from '../../components/Iconify'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { UserType } from '../../@types/user'
import useAuth from '../../hooks/useAuth'
import { useIsFeatureEnabled } from '../../hooks/useFeatures'
import { useLogout } from '../../hooks/useBootstrap'
import { styled } from '@mui/material/styles'
import { CompanyLogo } from '../../components/logo/CompanyLogo.tsx'

// this feels like a terrible way of doing it... there has to be a better way
const deriveDefaultTab = (pathname: string) => {
  if (pathname.includes('admin')) {
    return 'admin'
  } else if (pathname.includes('analytics')) {
    return 'analytics'
  } else if (pathname.includes('content-rooms')) {
    return 'content-rooms'
  } else if (
    pathname.includes('assets/search') ||
    pathname.includes('assets/detail')
  ) {
    return 'search'
  } else if (
    pathname.includes('upload')
  ) {
    return 'upload'
  } else if (
    pathname.includes('conversations')
  ){
    return 'content-conversations'
  }
  return false
}

export default function AssetHeader() {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { user } = useAuth()
  const { mutateAsync: logout } = useLogout()
  const contentRoomsEnabled = useIsFeatureEnabled("content-rooms")
  const contentConversationsEnabled = useIsFeatureEnabled("content-conversations")
  const userIsAdmin = user?.userType === UserType.ADMIN
  const userCanUpload = userIsAdmin || user?.userType === UserType.CREATOR
  const value = useMemo(() => deriveDefaultTab(pathname), [pathname])

  return (
    <Stack
      direction='column'
      spacing={2}
      sx={{ borderBottom: 1, borderColor: 'divider' }}
    >
      <Stack
        spacing={1}
        direction='row'
        justifyContent='space-between'
        sx={{ height: 24 }}
      >
        <Box>
          <CompanyLogo />
        </Box>

        <Stack
          spacing={1}
          direction='row'
        >
          <Button
            size='small'
            variant='text'
            startIcon={<Iconify icon='eva:person-outline' />}
            sx={{
              '& .MuiButton-startIcon': { marginRight: '4px' },
            }}
            onClick={() => navigate('/profile')}
          >Profile</Button>
          <Button
            size='small'
            variant='text'
            startIcon={<Iconify icon='eva:log-out-outline' />}
            sx={{
              '& .MuiButton-startIcon': { marginRight: '4px' },
            }}
            onClick={() => logout()}
          >Sign Out</Button>
        </Stack>

      </Stack>
      <Stack
        spacing={2}
        direction='row'
        justifyContent='space-between'
        alignItems='end'
      >
        <Tabs value={value}>
          {userCanUpload && <Tab label='Upload' value='upload' component={Link} to='/assets/upload' />}
          <Tab label='Search' value='search' component={Link} to='/assets/search' />
          {contentRoomsEnabled && <Tab label='Content Rooms' value='content-rooms' component={Link} to='/content-rooms' /> }
          {contentConversationsEnabled && (
            <Tab
              label={
                <Stack direction='row' spacing={.5} alignItems='center'>
                  <span>Conversations</span>
                  <BetaTagBox>beta</BetaTagBox>
                </Stack>
              }
              value='content-conversations'
              component={Link}
              to='/conversations' />
          )}
          <Tab label='Analytics' value='analytics' component={Link} to='/analytics' />
          {/*<Tab label="All Assets"/>*/}
          {userIsAdmin && <Tab label='Admin' value='admin' component={Link} to='/admin' />}
        </Tabs>
      </Stack>
    </Stack>
  )
}

const BetaTagBox = styled(Box)(({ theme }) => {
  return {
    color: 'inherit',
    borderRadius: theme.spacing(.5),
    padding: '2px 4px',
    border: `1px solid`,
    backgroundColor: 'transparent',
    display: 'inline-block',
    fontSize: '10px',
    textTransform: 'lowercase',
    marginLeft: '6px',
  }
})