import { Asset, AssetLabel, AssetType, isTaskStatusRunning, TaskStatus } from '../../@types/asset'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Iconify from '../Iconify'
import { useAssetTasks, useRetryTasksMutation } from '../../hooks/useAsset'

type AssetLabelsCmpProps = {
  asset?: Asset | null,
}

const title = "Labels"
const subtitle = "Objects detected using Masset AI"

export default function AssetLabelsCmp({ asset }: AssetLabelsCmpProps){
  const { data: assetTasks } = useAssetTasks(asset?.assetId)

  // only render for images for now
  if (!asset || asset?.assetType !== AssetType.IMAGE) {
    return <></>
  }

  // loading state based on status
  if (isTaskStatusRunning(assetTasks?.labels?.status)) {
    return <ProcessingLabelsCmp />
  }

  if (assetTasks?.labels?.status === TaskStatus.FAILED) {
    return <FailedLabelsCmp assetId={asset.assetId} />
  }

  if (assetTasks?.labels?.status === TaskStatus.MISSING) {
    return <MissingLabelsCmp assetId={asset.assetId} />
  }

  return (
    <Stack direction="column" spacing={2}>
      <Box>
        { assetTasks?.labels.labels.map(it => {
          return (
            <AssetLabelCmp key={it.assetLabelId} label={it} />
          )
        })}
      </Box>
    </Stack>
  )
}

function ProcessingLabelsCmp() {
  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={1}>
        <CircularProgress size={12}/>
        <Typography variant="small" color="text.deemphasized">Analyzing...</Typography>
      </Stack>
    </Stack>
  )
}

function FailedLabelsCmp({ assetId  }: { assetId: string }) {
  const { mutate: retryTasks } = useRetryTasksMutation()
  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="column" spacing={1}>
        <Typography variant="small" color="text.deemphasized">Hmmm... it looks like we couldn't detect your
          labels.</Typography>
        <Typography
          variant="small"
          color="primary.main"
          sx={{ cursor: "pointer" }}
          onClick={() => retryTasks(assetId)}>
          Try again?
        </Typography>
      </Stack>
    </Stack>
  )
}

function MissingLabelsCmp({ assetId }: { assetId: string }) {
  const { mutate: retryTasks } = useRetryTasksMutation()
  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="column" spacing={1}>
        <Typography variant="small" color="text.deemphasized">It looks like we've never detected your labels.</Typography>
        <Typography
          variant="small"
          color="primary.main"
          sx={{ cursor: "pointer" }}
          onClick={() => retryTasks(assetId)}>
          Start detection?
        </Typography>
      </Stack>
    </Stack>
  )
}

const StyleTagBox = styled(Box)(({theme}) => {
  const isLight = theme.palette.mode === 'light';
  const borderColor = isLight ? "transparent" : theme.palette.text.deemphasized
  return {
    color: theme.palette.text.deemphasized,
    borderRadius: theme.spacing(1),
    padding: "6px",
    border: `1px solid ${borderColor}`,
    backgroundColor: "transparent",
    display: "inline-block"
  }
})

function AssetLabelCmp({label} : {label: AssetLabel}){
  return (
    <StyleTagBox
      sx={{ mr: 1.5, mb: 2 }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Iconify icon="eva:bulb-outline"/>
        <Typography variant="smallHighlight">{label.labelName}</Typography>
      </Stack>

    </StyleTagBox>
  )
}