import { AssociatedGroup, SearchUser } from '../../../../@types/user.ts'
import { Box, Tooltip, Typography } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

export function UserTableRowUserGroups({ user }: { user: SearchUser }) {
  const orderedGroups = useMemo(() => orderBy(user.groups, 'name'), [user.groups])
  const first3 = orderedGroups.slice(0, 3)
  const overflow = orderedGroups.slice(3)
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
      }}>
      {first3.map(it => {
        return (
          <GroupTag key={it.groupId} group={it} />
        )
      })}
      {overflow.length > 0 && (
        <OverflowGroups groups={overflow} />
      )}
    </Box>
  )
}

function OverflowGroups({ groups }: { groups: AssociatedGroup[] }) {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: { backgroundColor: 'background.paper' },
        },
      }}
      title={
        <Box
          sx={{
            p: 1,
            maxWidth: 250,
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
          }}
        >
          {groups.map(it => <GroupTag key={it.groupId} group={it} />)}
        </Box>
      }
    >
      <StyleTagBox sx={{cursor: "pointer"}}>
        <Typography variant='tiny'>+ {groups.length}</Typography>
      </StyleTagBox>
    </Tooltip>
  )
}


function GroupTag({ group }: { group: AssociatedGroup }) {
  return (
    <StyleTagBox>
      <Typography variant='tiny'>{group.name}</Typography>
    </StyleTagBox>
  )
}

const StyleTagBox = styled(Box)(({ theme }) => {
  return {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(.25, 1),
    backgroundColor: alpha(theme.palette.primary.darker, .75),
    display: 'inline-block',
  }
})
