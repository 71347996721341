import { Category, CategoryValue } from '../../@types/category'
import { alpha, styled } from '@mui/material/styles'
import { Box, Stack, Typography } from '@mui/material'
import Iconify from '../Iconify'

type ReadOnlyTagProps = {
  categoryValue: CategoryValue,
  category: Category,
  onRemove?: (categoryValueId: string) => void
}

const StyleTagBox = styled(Box)(({ theme }) => {
  const isLight = theme.palette.mode === 'light'
  const borderColor = isLight ? 'transparent' : theme.palette.primary.main
  return {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    border: `1px solid ${borderColor}`,
    backgroundColor: isLight ? theme.palette.bg.warmGrey3 : alpha(theme.palette.primary.darker, .75),
    display: 'inline-block',
  }
})

export function ReadOnlyTag({ category, categoryValue, onRemove }: ReadOnlyTagProps) {
  const handleOnRemove = () => onRemove && onRemove(categoryValue.categoryValueId)
  return (
    <StyleTagBox sx={{ mr: 2, mb: 2 }}>
      <Stack direction='row' spacing={1} alignItems='center'>
        <Iconify color='primary.main' icon='eva:checkmark-circle-2-outline' />
        <Typography variant='smallHighlight'>{category.name} &gt; {categoryValue.value}</Typography>
        { onRemove && (
          <Iconify
            icon="eva:close-fill"
            onClick={handleOnRemove}
            sx={{cursor: "pointer"}}
          />
        )}
      </Stack>
    </StyleTagBox>
  )
}

