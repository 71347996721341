import { useFormContext, useWatch } from 'react-hook-form'
import { Avatar, Box, Stack, Typography } from '@mui/material'
import { RHFSelect } from '../../../hook-form'
import { GeneralAccessType, ShareLevel } from '../../../../@types/sharing'
import Iconify from '../../../Iconify'
import { useTheme } from '@mui/material/styles'
import { useAssetPermissionsDefaults } from '../field/AssetPermissionsDefaultsContext.tsx'

type GeneralAccessSelectorProps = {
  accessTypeName: string,
  accessLevelName: string,
  allowMixed?: boolean
}

export function GeneralAccessSelector({ accessTypeName, accessLevelName }: GeneralAccessSelectorProps) {
  // hooks
  const theme = useTheme()
  const { originalAccessType, originalAccessShareLevel } = useAssetPermissionsDefaults()
  const accessType = useWatch({ name: accessTypeName })
  const { formState: { dirtyFields } } = useFormContext()

  // access type
  const isRestricted = accessType == GeneralAccessType.RESTRICTED
  const isMixedAccessType = accessType == GeneralAccessType.MIXED
  const isOrganizationAccessType = accessType == GeneralAccessType.ORGANIZATION

  // detecting changes
  const accessTypeChanged = dirtyFields[accessTypeName]
  const shareLevelChanged = dirtyFields[accessLevelName]

  // ui pivoting
  const canSelectShareLevel = isOrganizationAccessType
  const icon = isMixedAccessType ? 'ph:question-mark' :
    isRestricted ? 'ph:lock' :
      'ph:buildings'
  const backgroundColor = isMixedAccessType ? theme.palette.action.disabled :
    isRestricted ? theme.palette.bg.warmGrey2 : theme.palette.primary.dark
  const isMixedAccessTypeAllowed = originalAccessType == GeneralAccessType.MIXED && !accessTypeChanged
  const isMixedShareLevelAllowed = originalAccessShareLevel == ShareLevel.MIXED && !shareLevelChanged

  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Stack direction='row' spacing={2} alignItems='center'>
        <Avatar sx={{ background: backgroundColor }}><Iconify icon={icon} /></Avatar>
        <Stack direction='column'>
          <Box>
            <RHFSelect
              name={accessTypeName}
              variant='standard'
              fullWidth={false}
              size='small'
              InputProps={{
                disableUnderline: true,
                sx: {
                  fontWeight: 'bold',
                  'select:focus': {
                    backgroundColor: 'transparent !important',
                  },
                },
              }}
            >
              <option value={GeneralAccessType.RESTRICTED}>Restricted</option>
              <option value={GeneralAccessType.ORGANIZATION}>All Internal Users</option>
              {isMixedAccessTypeAllowed && <option value={GeneralAccessType.MIXED}>Mixed</option>}
            </RHFSelect>
          </Box>
          <Box mt={-1}>
            {accessType == GeneralAccessType.RESTRICTED && (
              <Typography variant='small'>Only people with shared access can view the asset</Typography>
            )}
            {accessType == GeneralAccessType.ORGANIZATION && (
              <Typography variant='small'>All Masset users in your organization can access the asset</Typography>
            )}
            {isMixedAccessType && (
              <Stack mt={.5}>
                <Typography variant='small'>Some of the selected assets are Restricted and some are visible
                  Company-wide.</Typography>
                <Typography variant='small' color='text.deemphasized'>Unless changed, these settings will be
                  preserved.</Typography>
              </Stack>
            )}
          </Box>
        </Stack>
      </Stack>
      <Box>
        {
          canSelectShareLevel && (
            <RHFSelect
              name={accessLevelName}
              size='small'
              inputProps={{
                sx: {
                  fontSize: 12,
                  lineHeight: 1,
                },
              }}
            >
              {isMixedShareLevelAllowed && (<option value={ShareLevel.MIXED}>Mixed Roles</option>)}
              <option value={ShareLevel.EDIT}>Editor</option>
              <option value={ShareLevel.VIEW}>Viewer</option>
            </RHFSelect>
          )
        }

      </Box>

    </Stack>
  )
}