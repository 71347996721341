import { SearchAssetResult, SearchAssetResults, SearchDateFilter, SearchSortBy } from '../@types/search'
import { createContext, ReactNode, useEffect } from 'react'
import { ActionMap } from '../@types/reducer'
import { search } from '../clients/SearchClient'
import { useImmerReducer } from 'use-immer'
import { debounce } from '@mui/material'
import uniq from 'lodash/uniq'
import { useStoredSearchFilters } from './utils/searchStateHelpers'
import { setIsFavorite } from '../clients/AssetClient'
import { useSearchSelectedAssetsContext } from '../components/asset-search/SearchSelectedAssetsContext.tsx'

export enum SearchViewMode {
  LIST = 'LIST',
  CARDS = 'CARDS'
}

export const PREFS_SEARCH_PAGE_SIZE_KEY = '_prefs_search_page_size'
const pageSizePreference = window.localStorage.getItem(PREFS_SEARCH_PAGE_SIZE_KEY)

export const PREFS_SEARCH_PAGE_VIEW_MODE_KEY = '_prefs_search_page_view_mode'
const viewModeValue = window.localStorage.getItem(PREFS_SEARCH_PAGE_VIEW_MODE_KEY)
const viewModePreference = (Object.keys(SearchViewMode) as Array<keyof typeof SearchViewMode>)
  .find(key => SearchViewMode[key] === viewModeValue)

export const DEFAULT_PAGE_SIZE = pageSizePreference ? parseInt(pageSizePreference, 10) : 30
export const DEFAULT_VIEW_MODE = viewModePreference ?  SearchViewMode[viewModePreference] : SearchViewMode.CARDS
export const DEFAULT_SORT_BY = SearchSortBy.BEST_MATCH
export const DEFAULT_DATE_FILTER = SearchDateFilter.ALL


export type SearchState = {
  results: SearchAssetResult[],
  totalResultCount: number,
  viewMode: SearchViewMode,

  // filters
  filters: SearchFilters
}

export type SearchFilters = {
  currentPage: number,
  searchTerm?: string
  categoryValueIds: string[],
  sortBy: SearchSortBy,
  dateFilter: SearchDateFilter,
  includeOnlyFavorites: boolean,
  pageSize: number
}

type SearchActionCreators = {
  addCategoryValues: (categoryValueIds: string[]) => Promise<void>
  removeCategoryValues: (categoryValueIds: string[]) => Promise<void>
  deselectAllCategoryValues: () => Promise<void>
  setSortBy: (sortBy: SearchSortBy) => Promise<void>
  setDateFilter: (dateFilter: SearchDateFilter) => Promise<void>
  setIncludeOnlyFavorites: (includeOnlyFavorites: boolean) => Promise<void>
  setPage: (pageNumber: number) => Promise<void>
  setSearchTerm: (term: string) => Promise<void>
  setAssetSearchResultFavorite: (assetId: string, isFavorite: boolean) => Promise<void>
  setPageSize: (pageSize: number) => Promise<void>
  refreshAfterBulkAction: () => Promise<void>
  setViewMode: (viewMode: SearchViewMode) => Promise<void>
}

type SearchContextType = SearchState & SearchActionCreators
const SearchContext = createContext<SearchContextType>({} as SearchContextType)

enum ActionTypes {
  AddCategoryValues = 'ADD_CATEGORY_VALUES',
  RemoveCategoryValues = 'REMOVE_CATEGORY_VALUES',
  SetSortBy = 'SET_SORT_BY',
  SetDateFilter = 'SET_DATE_FILTER',
  SetIncludeOnlyFavorites = 'SET_INCLUDE_ONLY_FAVORITES',
  SetResults = 'SET_RESULTS',
  ClearResults = 'CLEAR_RESULTS',
  SelectAllForCategory = 'SELECT_ALL_FOR_CATEGORY',
  DeselectAllCategoryValues = 'DESELECT_ALL_CATEGORY_VALUES',
  SetPage = 'SET_PAGE',
  SetSearchTerm = 'SET_SEARCH_TERM',
  SetAssetIsFavorite = 'SET_ASSET_IS_FAVORITE',
  SetPageSize = 'SET_PAGE_SIZE',
  SetViewMode = 'SET_VIEW_MODE',
}

type SearchActionPayload = {
  [ActionTypes.AddCategoryValues]: { categoryValueIds: string[] }
  [ActionTypes.RemoveCategoryValues]: { categoryValueIds: string[] }
  [ActionTypes.SetSortBy]: { sortBy: SearchSortBy }
  [ActionTypes.SetDateFilter]: { dateFilter: SearchDateFilter }
  [ActionTypes.SetIncludeOnlyFavorites]: { includeOnlyFavorites: boolean }
  [ActionTypes.SetResults]: SearchAssetResults,
  [ActionTypes.ClearResults]: {}
  [ActionTypes.SelectAllForCategory]: { categoryId: string }
  [ActionTypes.DeselectAllCategoryValues]: {}
  [ActionTypes.SetPage]: { pageNumber: number }
  [ActionTypes.SetSearchTerm]: { searchTerm: string }
  [ActionTypes.SetAssetIsFavorite]: { assetId: string, isFavorite: boolean }
  [ActionTypes.SetPageSize]: { pageSize: number }
  [ActionTypes.SetViewMode]: { viewMode: SearchViewMode }
}
type SearchAction = ActionMap<SearchActionPayload>[keyof ActionMap<SearchActionPayload>];

const Reducer = (state: SearchState, action: SearchAction) => {
  switch (action.type) {
    case ActionTypes.SetResults:
      state.results = action.payload.results
      state.totalResultCount = action.payload.totalResultCount
      return
    case ActionTypes.ClearResults:
      state.results = []
      state.filters.currentPage = 0
      state.totalResultCount = 0
      return
    case ActionTypes.AddCategoryValues:
      state.filters.currentPage = 0
      state.filters.categoryValueIds.push(...action.payload.categoryValueIds)
      state.filters.categoryValueIds = uniq(state.filters.categoryValueIds)
      return
    case ActionTypes.RemoveCategoryValues:
      const toRemove = new Set(action.payload.categoryValueIds)
      state.filters.currentPage = 0
      state.filters.categoryValueIds = state.filters.categoryValueIds.filter(x => !toRemove.has(x))
      return
    case ActionTypes.DeselectAllCategoryValues:
      state.filters.currentPage = 0
      state.filters.categoryValueIds = []
      return
    case ActionTypes.SetDateFilter:
      state.filters.currentPage = 0
      state.filters.dateFilter = action.payload.dateFilter
      return
    case ActionTypes.SetSortBy:
      state.filters.currentPage = 0
      state.filters.sortBy = action.payload.sortBy
      return
    case ActionTypes.SetIncludeOnlyFavorites:
      state.filters.currentPage = 0
      state.filters.includeOnlyFavorites = action.payload.includeOnlyFavorites
      return
    case ActionTypes.SetPage:
      state.filters.currentPage = action.payload.pageNumber
      return
    case ActionTypes.SetSearchTerm:
      state.filters.currentPage = 0
      state.filters.searchTerm = action.payload.searchTerm
      return
    case ActionTypes.SetAssetIsFavorite:
      const asset = state.results.find(it => it.assetId === action.payload.assetId)
      if (asset) asset.isFavorite = action.payload.isFavorite
      return
    case ActionTypes.SetPageSize:
      state.filters.currentPage = 0
      state.filters.pageSize = action.payload.pageSize
      window.localStorage.setItem(PREFS_SEARCH_PAGE_SIZE_KEY, action.payload.pageSize + '')
      return
    case ActionTypes.SetViewMode:
      state.viewMode = action.payload.viewMode
      window.localStorage.setItem(PREFS_SEARCH_PAGE_VIEW_MODE_KEY, action.payload.viewMode + '')
      return
    default:
      return
  }
}

export const DEFAULT_FILTERS = {
  currentPage: 0,
  searchTerm: '',
  categoryValueIds: [],
  sortBy: DEFAULT_SORT_BY,
  dateFilter: DEFAULT_DATE_FILTER,
  includeOnlyFavorites: false,
  pageSize: DEFAULT_PAGE_SIZE,
}

const DEFAULT_INITIAL_STATE = {
  results: [],
  totalResultCount: 0,
  viewMode: DEFAULT_VIEW_MODE,
  filters: DEFAULT_FILTERS,
}
const SearchProvider = ({ children }: { children: ReactNode }) => {
  const { filters, storeFilters } = useStoredSearchFilters()
  const { setSelectedAssets, setSelectionModeEnabled } = useSearchSelectedAssetsContext()

  // load mode preference
  const viewModeValue = window.localStorage.getItem(PREFS_SEARCH_PAGE_VIEW_MODE_KEY)
  const viewModePreference = (Object.keys(SearchViewMode) as Array<keyof typeof SearchViewMode>)
    .find(key => SearchViewMode[key] === viewModeValue)

  const initialState = {
    ...DEFAULT_INITIAL_STATE,
    viewMode: viewModePreference ?  SearchViewMode[viewModePreference] : SearchViewMode.CARDS,
    filters: filters,
  }
  const [state, dispatch] = useImmerReducer(Reducer, initialState)

  function refreshResults() {
    const options = {
      searchTerm: state.filters.searchTerm,
      categoryValueIds: state.filters.categoryValueIds,
      dateUploadedFilter: state.filters.dateFilter,
      sortBy: state.filters.sortBy,
      onlyFavorites: state.filters.includeOnlyFavorites,
      offset: state.filters.currentPage * state.filters.pageSize,
      limit: state.filters.pageSize,
    }
    search(options).then((results: SearchAssetResults) => {
      storeFilters(state.filters)
      dispatch({ type: ActionTypes.SetResults, payload: results })
      setSelectedAssets(new Set())
      setSelectionModeEnabled(false)
    })
  }

  // anytime the filters change, re-issue the search
  useEffect(() => {
    refreshResults()
  }, [state.filters])

  const setPage = async (pageNumber: number) => {
    dispatch({ type: ActionTypes.SetPage, payload: { pageNumber } })
  }

  const addCategoryValues = async (categoryValueIds: string[]) =>
    dispatch({ type: ActionTypes.AddCategoryValues, payload: { categoryValueIds } })

  const removeCategoryValues = async (categoryValueIds: string[]) =>
    dispatch({ type: ActionTypes.RemoveCategoryValues, payload: { categoryValueIds } })

  const setSortBy = async (sortBy: SearchSortBy) =>
    dispatch({ type: ActionTypes.SetSortBy, payload: { sortBy } })

  const setDateFilter = async (dateFilter: SearchDateFilter) =>
    dispatch({ type: ActionTypes.SetDateFilter, payload: { dateFilter } })

  const setIncludeOnlyFavorites = async (includeOnlyFavorites: boolean) =>
    dispatch({ type: ActionTypes.SetIncludeOnlyFavorites, payload: { includeOnlyFavorites } })

  const deselectAllCategoryValues = async () =>
    dispatch({ type: ActionTypes.DeselectAllCategoryValues, payload: {} })

  const setSearchTerm = debounce(async (searchTerm: string) =>
      dispatch({ type: ActionTypes.SetSearchTerm, payload: { searchTerm } })
    , 250)

  const setAssetSearchResultFavorite = async (assetId: string, isFavorite: boolean) => {
    dispatch({ type: ActionTypes.SetAssetIsFavorite, payload: { assetId, isFavorite } })
    await setIsFavorite(assetId, isFavorite)
  }

  const setPageSize = async (pageSize: number) => {
    dispatch({ type: ActionTypes.SetPageSize, payload: { pageSize } })
  }

  const refreshAfterBulkAction = async () => {
    return refreshResults()
  }

  const setViewMode = async (viewMode: SearchViewMode) => dispatch({
    type: ActionTypes.SetViewMode,
    payload: { viewMode },
  })

  return (
    <SearchContext.Provider
      value={{
        ...state,
        addCategoryValues,
        removeCategoryValues,
        setSortBy,
        setDateFilter,
        setIncludeOnlyFavorites,
        deselectAllCategoryValues,
        setPage,
        setSearchTerm,
        setAssetSearchResultFavorite,
        setPageSize,
        refreshAfterBulkAction,
        setViewMode
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export { SearchContext, SearchProvider }
