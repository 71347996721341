import { ContentRoomPalette } from '../../@types/hosted-content-room'
import { ReactNode, useMemo } from 'react';
import { alpha, createTheme, ThemeProvider, useTheme } from '@mui/material/styles'
import { SimplePaletteColorOptions } from '@mui/material/styles/createPalette'
import componentsOverride from '../../theme/overrides'
import { darken, lighten } from '@mui/material'
import NotistackProvider from '../NotistackProvider.tsx'

type ContentRoomThemeProviderProps = {
  palette: ContentRoomPalette,
  children: ReactNode
}

export function ContentRoomThemeProvider({ palette: contentRoomPalette, children }: ContentRoomThemeProviderProps) {
  const defaultTheme = useTheme()
  const theme = useMemo(
    () => {
      const primaryColor = contentRoomPalette.primary
      const secondaryColor = contentRoomPalette.secondary
      const primaryOverride = primaryColor == 'default' || !isValidColor(primaryColor) ?
        defaultTheme.palette.primary :
        {
          lighter: lighten(primaryColor, .5),
          light: lighten(primaryColor, .3),
          main: primaryColor,
          dark: darken(primaryColor, .3),
          darker: darken(primaryColor, .6),
        }
      const secondaryOverride = secondaryColor == 'default' || !isValidColor(secondaryColor) ?
        defaultTheme.palette.secondary :
        {
          lighter: lighten(secondaryColor, .5),
          light: lighten(secondaryColor, .3),
          main: secondaryColor,
          dark: darken(secondaryColor, .3),
          darker: darken(secondaryColor, .6),
        }
      const options = {
        ...defaultTheme,
        palette: {
          ...defaultTheme.palette,
          primary: primaryOverride,
          secondary: secondaryOverride,
          background: {
            ...defaultTheme.palette.background,
            default: defaultTheme.palette.grey['900'],
            paper: defaultTheme.palette.grey['800']
          }
        },
        customShadows: {
          ...defaultTheme.customShadows,
          primary: `0 8px 16px 0 ${alpha(primaryOverride.main, 0.24)}`,
        },
      }

      const theme = createTheme(options)
      theme.components = componentsOverride(theme);

      // extra component overrides
      theme.components = {
        ...theme.components,

        // override the
        MuiAppBar: {
          styleOverrides: {
            root: {
              background: theme.palette.background.default,
              borderBottom: `1px solid ${theme.palette.divider}`
            }
          }
        }
      }

      return theme
    }, [contentRoomPalette, defaultTheme])

  // NotistackProvider is impacted by the theme, so we override it after
  return (
    <ThemeProvider theme={theme}>
      <NotistackProvider>
        {children}
      </NotistackProvider>
    </ThemeProvider>
  )
}

function isValidColor(color: string){
  if(!color) return false
  if(color.charAt(0) != "#") return false
  if(color.length != 4 && color.length != 7) return false
  return true
}