import {User} from "./user";
import { GeneralAccessType, ShareLevel } from './sharing'

export enum AssetType {
    AUDIO = 1,
    VIDEO = 2,
    IMAGE = 3,
    PRESENTATION = 4,
    DOCUMENT = 5,
    OTHER = 100
}

export enum AssetStorageType {
    INTERNAL_S3 = 1,
    EXTERNAL_HTTP = 2,
}

export enum AssetFileType {
    MP3 = 100,
    WAV = 101,
    MOV = 200,
    AVI = 201,
    MP4 = 202,
    JPG = 300,
    PNG = 301,
    TIFF = 302,
    PPTX = 400,
    PDF = 500,
    DOCX = 501
}

export type AudioMetadata = {
    assetType: AssetType,
    durationMs: number,
    channelType: string,
    sampleRate: number,
    artist: string,
    album: string,
    genre: string,
    title: string
}

export type VideoMetadata = {
    assetType: AssetType,
    heightPixels : number,
    widthPixels : number,
    fps: number,
    durationMs: number
}

export type ImageMetadata = {
    assetType: AssetType,
    heightPixels : number,
    widthPixels : number
}
export type PresentationMetadata = { assetType: AssetType }
export type DocumentMetadata = {
    assetType: AssetType,
    wordCount: number
}
export type OtherMetadata = {
    assetType: AssetType
}

export type AssetMetadata =
    AudioMetadata | VideoMetadata | ImageMetadata |
    PresentationMetadata | DocumentMetadata | OtherMetadata

export type Asset = {
    assetId: string,
    assetType: AssetType,
    name: string,
    description: string,
    fileSizeBytes: number,
    downloadCount: number,
    properties: AssetMetadata,
    createdAt: string,
    updatedAt: string,
    createdBy: User,
    selectedCategoryValueUUIDs: string[],
    previews: AssetPreviews,
    storageType: AssetStorageType,
    storageExternalRef: string
    palettes: AssetPalettes
    labels: AssetLabels,
    isFavorite: boolean,
    accessLevel: ShareLevel,
    generalAccessType: GeneralAccessType,
    generalAccessLevel: ShareLevel | null,
    isInternal: boolean,
    isThirdParty: boolean
}

export type AssetPalettes = {
    status: TaskStatus,
    general: AssetPalette
    foreground: AssetPalette
    background: AssetPalette
}

export type AssetPalette = {
    paletteType: PaletteType
    colors: AssetColor[]
}

export type AssetColor = {
    red: number,
    green: number,
    blue: number,
    hex: string,
    pixelPercent: number,
    paletteType: PaletteType
}

export type AssetLabels = {
    status : TaskStatus
    labels : AssetLabel[]
}

export type AssetLabel = {
    assetLabelId: string
    labelName: string
    confidence: number
}

export enum TaskStatus {
    QUEUED = 1,
    PROCESSING = 2,
    SUCCEEDED = 3,
    FAILED = 4,
    MISSING = 5
}

export const isTaskStatusRunning = (ts: TaskStatus | undefined) => ts === TaskStatus.QUEUED || ts === TaskStatus.PROCESSING

export enum PaletteType {
    GENERAL = 1,
    FOREGROUND = 2,
    BACKGROUND = 3,
}

export const PaletteTypeNames = {
    [PaletteType.GENERAL]: "General",
    [PaletteType.BACKGROUND]: "Background",
    [PaletteType.FOREGROUND]: "Foreground",
}

export enum AssetPreviewType {
    THUMBNAIL_SM = 1,
    THUMBNAIL_MD = 2,
    THUMBNAIL_LG = 3,
    THUMBNAIL_XL = 4,
}

export type AssetPreview = {
    assetPreviewId: string,
    displayOrder: number
}

export type AssetPreviews = {
    assetPreviews: AssetPreview[]
}

export type UpdateAssetDto = {
    name: string,
    description: string,
    assetType: AssetType,
    properties: AssetMetadata,
    selectedCategoryValueUUIDs: string[],
    isInternal: boolean,
    isThirdParty: boolean
}

export type BulkUpdateAssetsDto = {
    assetIds: string[],
    shouldBulkUpdateName: boolean,
    shouldBulkUpdateDescription: boolean,
    name: string,
    description: string,
    selectedCategoryValueUUIDs: string[],
    generalAccessType?:  GeneralAccessType,
    generalAccessShareLevel?: ShareLevel,
    isInternal: boolean,
    isThirdParty: boolean
}

export type RequestChangeDto = {
    assetId: string,
    departmentId: string | null,
    changeDescription: string
}

export type RequestNewAssetDto = {
    fileType: string,
    message: string
}

export type RequestChangeResult = {
    changeRequestId: string,
    assetId: string,
    departmentId: string | null,
    changeDescription: string
}

export type CreateAssetFeedbackDto = {
    assetId: string,
    departmentId: string | null,
    message: string
}

export type FlagAssetDto = {
    message?: string | ''
}

export type AssetFeedbackDto = {
    feedbackId: string,
    assetId: string,
    departmentId: string | null,
    feedback: string
}

export type AssetShareRequestDto = {
    assetId: string,
    emails: string[],
    message: string
}

export type AssetShareResponseDto = {
    shares: AssetShare[]
}

export type AssetShare = {
    assetShareId: string,
    email: string
}

export type AssetTasksResponseDto = {
    assetId: string,
    palettes: AssetPalettes,
    labels: AssetLabels
}

export type LinkedAssetsImportResult = {
    type: BulkImportResultType
    assets: Asset[]
    ignored: Asset[]
    errors: BulkImportLinkedAssetWithErrors[]
}

export type LinkedAssetsImportError = {
    recordNumber: number,
    errorType: LinkedAssetsImportErrorType
}

export type BulkImportLinkedAssetWithErrors = {
    asset: BulkImportLinkedAsset | null,
    errors: LinkedAssetsImportError[]
}

export type BulkImportLinkedAsset = {
    name: string,
    url: string,
    lineNumber: number
}

export enum LinkedAssetsImportErrorType {
    MISSING_NAME = 1,
    MISSING_LINK = 2
}

export enum BulkImportResultType {
    INVALID_SCHEMA = 1,
    INVALID_FILE_FORMAT = 2,
    VALID = 3,
    PROCESSED = 4,
}

export type AssetFavoriteResponseDto = {
    isFavorite: boolean
}

export type AssetBulkDeleteResponseDto = {}
export type AssetBulkUpdateTagsResponseDto = {}
export type AssetBulkTagStateResponseDto = {
    selectedCategoryValueIds: string[]
    partialSelectedCategoryValueIds: string[]
}

export type NewVersionDto = {
    notifyDownloaders: boolean
    notifySharers: boolean
    notifyViewers: boolean
}

export enum AssetPermission {
  DOWNLOAD = 1,
  SHARE = 2,
  REQUEST_CHANGE = 3,
  GIVE_FEEDBACK = 4,
  FLAG = 5,
  EDIT_METADATA = 6,
  UPLOAD_NEW_VERSION = 7,
  DELETE = 8,
  FAVORITE = 9,
  OVERRIDE_PREVIEW = 10,
  RETRY_TASKS = 11,
  EXTERNAL_SHARE = 12
}